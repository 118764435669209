var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.educationalCategory.educationalCategoryImagePath,
            _vm.educationalCategory.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus},on:{"changeValue":function($event){_vm.educationalCategory.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-11',"id":(_vm.id + "-parentEducationalCategoryToken"),"value":_vm.educationalCategory.parentEducationalCategoryToken,"options":_vm.parentEducationalCategoryTokenOptions,"title":_vm.$t('EducationalCategories.parent'),"imgName":'EducationalCategories.svg'},on:{"changeValue":function($event){_vm.educationalCategory.parentEducationalCategoryToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-educationalCategoryNameAr"),"errors":_vm.errors_educationalCategoryNameAr,"value":_vm.educationalCategory.educationalCategoryNameAr,"title":_vm.$t('general.nameAr'),"imgName":'EducationalCategories.svg'},on:{"changeValue":function($event){_vm.educationalCategory.educationalCategoryNameAr = $event;
          _vm.$v.educationalCategory.educationalCategoryNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-educationalCategoryNameEn"),"errors":_vm.errors_educationalCategoryNameEn,"value":_vm.educationalCategory.educationalCategoryNameEn,"title":_vm.$t('general.nameEn'),"imgName":'EducationalCategories.svg'},on:{"changeValue":function($event){_vm.educationalCategory.educationalCategoryNameEn = $event;
          _vm.$v.educationalCategory.educationalCategoryNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-educationalCategoryDescriptionAr"),"errors":_vm.errors_educationalCategoryDescriptionAr,"value":_vm.educationalCategory.educationalCategoryDescriptionAr,"title":_vm.$t('general.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.educationalCategory.educationalCategoryDescriptionAr = $event;
          _vm.$v.educationalCategory.educationalCategoryDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-educationalCategoryDescriptionEn"),"errors":_vm.errors_educationalCategoryDescriptionEn,"value":_vm.educationalCategory.educationalCategoryDescriptionEn,"title":_vm.$t('general.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.educationalCategory.educationalCategoryDescriptionEn = $event;
          _vm.$v.educationalCategory.educationalCategoryDescriptionEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-educationalCategoryNotes"),"value":_vm.educationalCategory.educationalCategoryNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.educationalCategory.educationalCategoryNotes = $event}}})],1),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }