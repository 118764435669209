var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"isDisabled":true,"className":'col-md-11',"id":(_vm.id + "-educationalCourseLevelToken"),"value":_vm.model.filterData.educationalCourseLevelToken,"options":_vm.educationalCourseLevelTokenOptions,"title":_vm.$t('EducationalCourseLevels.select'),"imgName":'EducationalCourseLevels.svg'},on:{"changeValue":function($event){_vm.model.filterData.educationalCourseLevelToken = $event}}}),(!_vm.isTeacherLogged)?_c('CustomSelectBox',{attrs:{"className":'col-md-11',"id":"educationalCourseLevelsModel-filter-ownerUserToken","value":_vm.educationalCourseLevelsModel.filterData.ownerUserToken,"options":_vm.userTokenOptions,"title":_vm.$t('Users.select'),"imgName":'user.svg'},on:{"changeValue":function($event){_vm.educationalCourseLevelsModel.filterData.ownerUserToken = $event;
          _vm.getQuestionsDialog();}}}):_vm._e()],1),(_vm.model.filterData.educationalCourseLevelToken && _vm.isTeacherLogged)?[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-11',"id":(_vm.id + "-questionBankTitleToken"),"value":_vm.model.filterData.questionBankTitleToken,"options":_vm.questionBankTitleTokenOptions,"title":_vm.$t('QuestionBankTitles.select'),"imgName":'questionBankTitle.svg'},on:{"changeValue":function($event){_vm.model.filterData.questionBankTitleToken = $event;
            _vm.model.filterData.questionBankToken = $event
              ? _vm.model.filterData.questionBankToken
              : '';
            _vm.model.filterData.questionParagraphToken = $event
              ? _vm.model.filterData.questionParagraphToken
              : '';
            _vm.getQuestionBanksDialog();
            _vm.getQuestionsDialog();}}}),(_vm.model.filterData.questionBankTitleToken)?[_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-questionParagraphToken"),"value":_vm.model.filterData.questionParagraphToken,"options":_vm.questionParagraphTokenOptions,"title":_vm.$t('QuestionParagraphs.select'),"imgName":'questionParagraph.svg'},on:{"changeValue":function($event){_vm.model.filterData.questionParagraphToken = $event;
              _vm.filterQuestionsList();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-questionBankToken"),"value":_vm.model.filterData.questionBankToken,"options":_vm.questionBankTokenOptions,"title":_vm.$t('QuestionBanks.select'),"imgName":'questionBank.svg'},on:{"changeValue":function($event){_vm.model.filterData.questionBankToken = $event;
              _vm.getQuestionsDialog();}}})]:_vm._e()],2)]:_vm._e(),(_vm.model.filterData.educationalCourseLevelToken)?[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:("SystemQuestions"),expression:"`SystemQuestions`"}],staticClass:"btn btn-lg btn-collapse arrow-end"},[_c('span',[_vm._v(_vm._s(_vm.$t("EducationalCourseLevelQuestions.systemQuestions"))+" ")]),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"SystemQuestions"}},[_c('div',{staticClass:"row data-collapse"},[_c('div',{key:_vm.reRenderKey,staticClass:"row"},[[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("Questions.modelName"))+" ")]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("general.select")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("general.title"))+" ")]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("QuestionBanks.name"))+" ")]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("QuestionParagraphs.name"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.type"))+" ")])])]),_c('tbody',[(_vm.questionOptionsList.length == 0)?[_c('tr',[_c('th',{attrs:{"colspan":"6"}},[_vm._v(" "+_vm._s(_vm.$t("general.noData"))+" ")])])]:_vm._l((_vm.questionOptionsList),function(item,indexQuestionOption){return _c('tr',{key:indexQuestionOption},[_c('td',[_vm._v(_vm._s(indexQuestionOption + 1))]),_c('td',[_c('CustomCheckbox',{attrs:{"id":(_vm.id + "-table-" + indexQuestionOption + "-isSelected"),"value":item.isSelected,"centerStatus":true,"withOutTitle":true},on:{"changeValue":function($event){item.isSelected = $event;
                                _vm.toggleItemInMainList($event, item);}}})],1),_c('td',[_vm._v(" "+_vm._s(item.questionTitle)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionBankName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionParagraphTitle || "--")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionType)+" ")])])})],2)])])])]],2)])]),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:("SelectedQuestions"),expression:"`SelectedQuestions`"}],staticClass:"btn btn-lg btn-collapse arrow-end mt-3"},[_c('span',[_vm._v(_vm._s(_vm.$t("EducationalCourseLevelQuestions.selectedQuestions"))+" ")]),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"SelectedQuestions"}},[_c('div',{staticClass:"row data-collapse"},[_c('div',{staticClass:"row"},[[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("Questions.modelName"))+" ")]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("general.delete")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("general.title"))+" ")]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("QuestionBanks.name"))+" ")]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("QuestionParagraphs.name"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.type"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.degree"))+" ")])])]),_c('tbody',[(_vm.model.questionsData.length == 0)?[_c('tr',[_c('th',{attrs:{"colspan":"7"}},[_vm._v(" "+_vm._s(_vm.$t("general.noData"))+" ")])])]:_vm._l((_vm.model.questionsData),function(item,indexQuestion){return _c('tr',{key:indexQuestion},[_c('td',[_vm._v(_vm._s(indexQuestion + 1))]),_c('td',[_c('button',{attrs:{"title":_vm.$t('general.delete')},on:{"click":function($event){$event.preventDefault();return _vm.removeSliceQuestion(
                                  indexQuestion,
                                  item.questionToken
                                )}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]),_c('td',[_vm._v(" "+_vm._s(item.questionTitle)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionBankName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionParagraphTitle || "--")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.questionType)+" ")]),_c('td',[_c('CustomInputFloat',{attrs:{"id":(_vm.id + "-table-" + indexQuestion + "-questionDegree"),"value":item.questionDegree,"withOutDesign":true},on:{"changeValue":function($event){item.questionDegree = $event}}})],1)])})],2)])])])]],2)])])]:_vm._e(),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }