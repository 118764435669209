<template>
  <div id="test" class="page-container test">test</div>
</template>

<script>
export default {
  name: "Test",
  mixins: [],
  beforeCreate() {},
  props: {},
  data() {
    return {};
  },
  created() {},
  watch: {},
  beforeMount() {},
  mounted() {},
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss"></style>
