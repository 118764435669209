var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.questionBankTitle.questionBankTitleImagePath,
            _vm.questionBankTitle.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus},on:{"changeValue":function($event){_vm.questionBankTitle.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankTitleNameAr"),"errors":_vm.errors_questionBankTitleNameAr,"value":_vm.questionBankTitle.questionBankTitleNameAr,"title":_vm.$t('general.nameAr'),"imgName":'questionBankTitle.svg'},on:{"changeValue":function($event){_vm.questionBankTitle.questionBankTitleNameAr = $event;
          _vm.$v.questionBankTitle.questionBankTitleNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankTitleNameEn"),"errors":_vm.errors_questionBankTitleNameEn,"value":_vm.questionBankTitle.questionBankTitleNameEn,"title":_vm.$t('general.nameEn'),"imgName":'questionBankTitle.svg'},on:{"changeValue":function($event){_vm.questionBankTitle.questionBankTitleNameEn = $event;
          _vm.$v.questionBankTitle.questionBankTitleNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankTitleDescriptionAr"),"errors":_vm.errors_questionBankTitleDescriptionAr,"value":_vm.questionBankTitle.questionBankTitleDescriptionAr,"title":_vm.$t('general.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.questionBankTitle.questionBankTitleDescriptionAr = $event;
          _vm.$v.questionBankTitle.questionBankTitleDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankTitleDescriptionEn"),"errors":_vm.errors_questionBankTitleDescriptionEn,"value":_vm.questionBankTitle.questionBankTitleDescriptionEn,"title":_vm.$t('general.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.questionBankTitle.questionBankTitleDescriptionEn = $event;
          _vm.$v.questionBankTitle.questionBankTitleDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-questionBankTitleNotes"),"value":_vm.questionBankTitle.questionBankTitleNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.questionBankTitle.questionBankTitleNotes = $event}}})],1),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }