var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.userLoginCode.userLoginCodeImagePath,
            _vm.userLoginCode.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus},on:{"changeValue":function($event){_vm.userLoginCode.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.userLoginCode.fullCode,"title":_vm.$t('UserLoginCodes.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userLoginCode.fullCode = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userToken"),"errors":_vm.errors_userToken,"value":_vm.userLoginCode.userToken,"options":_vm.userTokenOptions,"title":_vm.$t('Users.select'),"imgName":'user.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userToken = $event;
          _vm.$v.userLoginCode.userToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userLoginCodePlatFromToken"),"errors":_vm.errors_userLoginCodePlatFromToken,"value":_vm.userLoginCode.userLoginCodePlatFromToken,"options":_vm.userLoginCodePlatFromTokenOptions,"title":_vm.$t('PlatFroms.select'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodePlatFromToken = $event;
          _vm.$v.userLoginCode.userLoginCodePlatFromToken.$touch();}}}),_c('CustomInput',{attrs:{"isSync":true,"syncTitle":_vm.$t('UserLoginCodes.loginCodeSuggestion'),"syncImgName":'sync.svg',"className":'col-md-6',"id":(_vm.id + "-loginCode"),"value":_vm.userLoginCode.loginCode,"title":_vm.$t('UserLoginCodes.loginCode'),"imgName":'UserLoginCodes.svg'},on:{"sync":function($event){return _vm.loginCodeSuggestion()},"changeValue":function($event){_vm.userLoginCode.loginCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userLoginCodeNameAr"),"errors":_vm.errors_userLoginCodeNameAr,"value":_vm.userLoginCode.userLoginCodeNameAr,"title":_vm.$t('UserLoginCodes.nameAr'),"imgName":'UserLoginCodes.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodeNameAr = $event;
          _vm.$v.userLoginCode.userLoginCodeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userLoginCodeNameEn"),"errors":_vm.errors_userLoginCodeNameEn,"value":_vm.userLoginCode.userLoginCodeNameEn,"title":_vm.$t('UserLoginCodes.nameEn'),"imgName":'UserLoginCodes.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodeNameEn = $event;
          _vm.$v.userLoginCode.userLoginCodeNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userLoginCodeDescriptionAr"),"errors":_vm.errors_userLoginCodeDescriptionAr,"value":_vm.userLoginCode.userLoginCodeDescriptionAr,"title":_vm.$t('UserLoginCodes.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodeDescriptionAr = $event;
          _vm.$v.userLoginCode.userLoginCodeDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-userLoginCodeDescriptionEn"),"errors":_vm.errors_userLoginCodeDescriptionEn,"value":_vm.userLoginCode.userLoginCodeDescriptionEn,"title":_vm.$t('UserLoginCodes.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodeDescriptionEn = $event;
          _vm.$v.userLoginCode.userLoginCodeDescriptionEn.$touch();}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-12',"value":_vm.userLoginCode.codeIsActiveUnTileUserSignOut,"title":_vm.$t('UserLoginCodes.codeIsActiveUnTileUserSignOut')},on:{"changeValue":function($event){_vm.userLoginCode.codeIsActiveUnTileUserSignOut = $event}}}),(!_vm.userLoginCode.codeIsActiveUnTileUserSignOut)?[_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-codeActiveFromDateTime"),"type":"dateTime","value":_vm.userLoginCode.codeActiveFromDateTime,"title":_vm.$t('UserLoginCodes.codeActiveFromDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.userLoginCode.codeActiveFromDateTime = $event.dateTime}}}),_c('DateTimePicker',{staticClass:"col-md-6",attrs:{"id":(_vm.id + "-codeActiveToDateTime"),"type":"dateTime","value":_vm.userLoginCode.codeActiveToDateTime,"title":_vm.$t('UserLoginCodes.codeActiveToDateTime'),"language":_vm.language},on:{"changeValue":function($event){_vm.userLoginCode.codeActiveToDateTime = $event.dateTime}}})]:_vm._e(),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-userLoginCodeNotes"),"value":_vm.userLoginCode.userLoginCodeNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userLoginCode.userLoginCodeNotes = $event}}})],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }