<template>
  <div class="app-footer-container page-footer">
    <a class="txt2" href="https://admins-egypt.com" target="_blank">
      {{ $t("copyRight") }}
    </a>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss"></style>
