var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.userWalletTransaction.userWalletTransactionImagePath,
            _vm.userWalletTransaction.defaultImg
          )},on:{"changeValue":function($event){_vm.userWalletTransaction.mediaFile = $event.file}}})],1),_c('div',{staticClass:"row"},[(!_vm.isPaySalary)?[_c('CustomSelectBox',{attrs:{"className":'col-md-10',"id":(_vm.id + "-userToken"),"errors":_vm.errors_userToken,"value":_vm.userWalletTransaction.userToken,"options":_vm.userTokenOptions,"title":_vm.$t('Users.select'),"imgName":'user.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userToken = $event;
            _vm.$v.userWalletTransaction.userToken.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-5',"id":(_vm.id + "-fullCode"),"value":_vm.userWalletTransaction.fullCode,"title":_vm.$t('UserWalletTransactions.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.fullCode = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-userWalletTransactionTypeToken"),"errors":_vm.errors_userWalletTransactionTypeToken,"value":_vm.userWalletTransaction.userWalletTransactionTypeToken,"options":_vm.userWalletTransactionTypeTokenOptions,"title":_vm.$t('UserWalletTransactions.type'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionTypeToken = $event;
            _vm.$v.userWalletTransaction.userWalletTransactionTypeToken.$touch();}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-5',"id":(_vm.id + "-userWalletTransactionValue"),"errors":_vm.errors_userWalletTransactionValue,"value":_vm.userWalletTransaction.userWalletTransactionValue,"title":_vm.$t('general.value'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionValue = $event;
            _vm.$v.userWalletTransaction.userWalletTransactionValue.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-userWalletTransactionMethodToken"),"value":_vm.userWalletTransaction.userWalletTransactionMethodToken,"options":_vm.userWalletTransactionMethodTokenOptions,"title":_vm.$t('paymentMethod'),"imgName":'paymentMethods.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionMethodToken = $event}}}),_c('TextArea',{attrs:{"className":'col-md-10',"id":(_vm.id + "-userWalletTransactionNotes"),"value":_vm.userWalletTransaction.userWalletTransactionNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionNotes = $event}}})]:[_c('CustomInput',{attrs:{"className":'col-md-5',"id":(_vm.id + "-fullCode"),"value":_vm.userWalletTransaction.fullCode,"title":_vm.$t('UserWalletTransactions.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.fullCode = $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-5',"id":(_vm.id + "-userWalletTransactionValue"),"errors":_vm.errors_userWalletTransactionValue,"value":_vm.userWalletTransaction.userWalletTransactionValue,"title":_vm.$t('general.value'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionValue = $event;
            _vm.$v.userWalletTransaction.userWalletTransactionValue.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-10',"id":(_vm.id + "-userWalletTransactionMethodToken"),"value":_vm.userWalletTransaction.userWalletTransactionMethodToken,"options":_vm.userWalletTransactionMethodTokenOptions,"title":_vm.$t('paymentMethod'),"imgName":'paymentMethods.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionMethodToken = $event}}}),_c('TextArea',{attrs:{"className":'col-md-10',"id":(_vm.id + "-userWalletTransactionNotes"),"value":_vm.userWalletTransaction.userWalletTransactionNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.userWalletTransaction.userWalletTransactionNotes = $event}}})]],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }