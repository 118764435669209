var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[(_vm.isAdd)?[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-11',"id":(_vm.id + "-mainQuestionBankTitleToken"),"value":_vm.questionModel.mainQuestionBankTitleToken,"options":_vm.questionBankTitleTokenOptions,"title":_vm.$t('QuestionBankTitles.select'),"imgName":'questionBankTitle.svg'},on:{"changeValue":function($event){_vm.questionModel.mainQuestionBankTitleToken = $event;
            _vm.questionModel.mainQuestionBankToken = '';
            _vm.questionModel.mainQuestionParagraphToken = '';
            _vm.mainQuestionBankTitleTokenChanged($event);
            _vm.getQuestionBanksDialog();
            _vm.getQuestionParagraphsDialog();}}}),(_vm.questionModel.mainQuestionBankTitleToken)?[_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-mainQuestionParagraphToken"),"value":_vm.questionModel.mainQuestionParagraphToken,"options":_vm.questionParagraphTokenOptions,"title":_vm.$t('QuestionParagraphs.select'),"imgName":'questionParagraph.svg'},on:{"changeValue":function($event){_vm.questionModel.mainQuestionParagraphToken = $event;
              _vm.mainQuestionParagraphTokenChanged($event);}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-mainQuestionBankToken"),"value":_vm.questionModel.mainQuestionBankToken,"options":_vm.questionBankTokenOptions,"title":_vm.$t('QuestionBanks.select'),"imgName":'questionBank.svg'},on:{"changeValue":function($event){_vm.questionModel.mainQuestionBankToken = $event;
              _vm.mainQuestionBankTokenChanged($event);}}})]:_vm._e()],2)]:_vm._e(),[_vm._l((_vm.questionsList),function(question,indexQuestion){return _c('div',{key:indexQuestion,staticClass:"my-card"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(indexQuestion + 1))]),(_vm.questionsList.length > 1)?_c('span',{staticClass:"remove-slice-container"},[_c('button',{staticClass:"btn btn-remove-slice",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeQuestionSlice(indexQuestion)}}},[_vm._v(" × ")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
                question.questionMediaPath,
                question.defaultImg
              ),"deleteFileStatus":_vm.deleteFileStatus},on:{"changeValue":function($event){question.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"row"},[_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-" + indexQuestion + "-questionTitle"),"value":question.questionTitle,"title":_vm.$t('Questions.title'),"imgName":'question.svg'},on:{"changeValue":function($event){question.questionTitle = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-" + indexQuestion + "-questionParagraphToken"),"value":question.questionParagraphToken,"options":_vm.questionParagraphTokenOptions,"title":_vm.$t('QuestionParagraphs.select'),"imgName":'questionParagraph.svg'},on:{"changeValue":function($event){question.questionParagraphToken = $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-" + indexQuestion + "-questionBankToken"),"value":question.questionBankToken,"options":_vm.questionBankTokenOptions,"title":_vm.$t('QuestionBanks.select'),"imgName":'questionBank.svg'},on:{"changeValue":function($event){question.questionBankToken = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-" + indexQuestion + "-questionAnswerDescription_HTML"),"value":question.questionAnswerDescription_HTML,"title":_vm.$t('Questions.answerDescription'),"imgName":'description.svg',"rows":7},on:{"changeValue":function($event){question.questionAnswerDescription_HTML = $event}}}),_vm._v(" "),_c('CustomFileInput',{attrs:{"className":'col-md-6',"defaultImg":_vm.fullPathFileFromServer(
                question.questionDescription_MediaPath,
                question.defaultImg
              ),"title":_vm.$t('Questions.answerDescriptionImg')},on:{"changeValue":function($event){question.questionDescription_Media = $event.file}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":(_vm.id + "-" + indexQuestion + "-questionTypeToken"),"value":question.questionTypeToken,"options":_vm.questionTypeTokensOptions,"title":_vm.$t('questionsTypes.select'),"imgName":'type.svg'},on:{"changeValue":function($event){question.questionTypeToken = $event}}}),(question.questionTypeToken == _vm.QUESTION_TYPE.TrueFalse)?[_c('CustomCheckbox',{attrs:{"className":'col-md-12 centering mt-3',"value":question.trueFalseQuestionData.questionAnswer,"title":_vm.$t('Questions.rightAnswer')},on:{"changeValue":function($event){question.trueFalseQuestionData.questionAnswer = $event}}})]:_vm._e(),(question.questionTypeToken == _vm.QUESTION_TYPE.Chose)?[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("Questions.answers"))+" ")]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("general.delete")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(" "+_vm._s(_vm.$t("Questions.answerText"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Questions.rightAnswer"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.image"))+" ")])])]),_c('tbody',[(
                        question.mCQQuestionData.questionMCQAnswerData
                          .length == 0
                      )?[_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t("Questions.noAnswers"))+" ")])])]:_vm._l((question.mCQQuestionData
                          .questionMCQAnswerData),function(item,indexAnswer){return _c('tr',{key:indexAnswer},[_c('td',[_vm._v(_vm._s(indexAnswer + 1))]),_c('td',[_c('button',{attrs:{"title":_vm.$t('general.delete')},on:{"click":function($event){$event.preventDefault();return _vm.removeSliceAnswer(indexQuestion, indexAnswer)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]),_c('td',[_c('TextArea',{attrs:{"id":(_vm.id + "-table-" + indexAnswer + "-answerText"),"value":item.answerText,"withOutDesign":true},on:{"changeValue":function($event){item.answerText = $event}}})],1),_c('td',[_c('CustomCheckbox',{attrs:{"id":(_vm.id + "-table-" + indexAnswer + "-answerIsTrue"),"value":item.answerIsTrue,"centerStatus":true,"withOutTitle":true},on:{"changeValue":function($event){item.answerIsTrue = $event;
                              _vm.unchekExept(indexQuestion, indexAnswer);}}})],1),_c('td',[(indexAnswer == 0)?_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
                                question.questionAnswerA_MediaPath,
                                question.defaultImg
                              )},on:{"changeValue":function($event){question.questionAnswerA_Media = $event.file}}}):_vm._e(),(indexAnswer == 1)?_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
                                question.questionAnswerB_MediaPath,
                                question.defaultImg
                              )},on:{"changeValue":function($event){question.questionAnswerB_Media = $event.file}}}):_vm._e(),(indexAnswer == 2)?_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
                                question.questionAnswerC_MediaPath,
                                question.defaultImg
                              )},on:{"changeValue":function($event){question.questionAnswerC_Media = $event.file}}}):_vm._e(),(indexAnswer == 3)?_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
                                question.questionAnswerD_MediaPath,
                                question.defaultImg
                              )},on:{"changeValue":function($event){question.questionAnswerD_Media = $event.file}}}):_c('span',[_vm._v("--")])],1)])}),(
                        question.mCQQuestionData.questionMCQAnswerData
                          .length <= 3
                      )?[_c('tr',[_c('th',{attrs:{"colspan":"7"}},[_c('button',{staticClass:"btn btn-primary btn-block",on:{"click":function($event){$event.preventDefault();return _vm.addSliceAnswer(indexQuestion)}}},[_vm._v(" "+_vm._s(_vm.$t("general.add"))+" ")])])])]:_vm._e()],2)])])])]:_vm._e()],2)])}),(!_vm.isUpdate)?_c('div',{staticClass:"add-slice-container"},[_c('button',{staticClass:"btn btn-add-slice",attrs:{"type":"button","title":_vm.$t('addSlice')},on:{"click":function($event){return _vm.addQuestionSlice()}}},[_vm._v(" + ")])]):_vm._e()],_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }