var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.questionsData.length)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"colspan":"5"}},[_vm._v(_vm._s(_vm.$t("Questions.data")))]),_vm._m(0)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("media")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("Questions.title")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("QuestionBanks.name")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("QuestionParagraphs.name")))]),_c('th',{staticClass:"cell-lg"},[_vm._v(_vm._s(_vm.$t("questionsTypes.name")))])])]),_c('tbody',_vm._l((_vm.questionsData),function(question,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(++index + _vm.filterData.currentIndex)+" ")]),_c('td',[_c('button',{attrs:{"title":_vm.$t('media')},on:{"click":function($event){_vm.setQuestionData(question);
              _vm.openBottomSheet(_vm.model.metaData.components.Media.refName);}}},[(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.image
              )?_c('img',{attrs:{"src":require("@/assets/images/image.svg")}}):(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.video
              )?_c('img',{attrs:{"src":require("@/assets/images/video.svg")}}):(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.audio
              )?_c('img',{attrs:{"src":require("@/assets/images/audio.svg")}}):(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.pdf
              )?_c('img',{attrs:{"src":require("@/assets/images/pdf.svg")}}):(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.excel
              )?_c('img',{attrs:{"src":require("@/assets/images/excel.svg")}}):(
                question.questionMediaTypeToken == _vm.MEDIA_TYPE_TOKENS.word
              )?_c('img',{attrs:{"src":require("@/assets/images/word.svg")}}):(
                question.questionMediaTypeToken ==
                  _vm.MEDIA_TYPE_TOKENS.powerPoint
              )?_c('img',{attrs:{"src":require("@/assets/images/powerpoint.svg")}}):_c('img',{attrs:{"src":require("@/assets/images/media.svg")}})])]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(question.questionTitle))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(question.questionBankInfoData.questionBankNameCurrent))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( question.questionParagraphInfoData ? question.questionParagraphInfoData.questionParagraphTitle : "" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(question.questionTypeNameCurrent))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[_c('FloatingMenu',[_c('li',[_c('button',{attrs:{"title":_vm.$t('general.info')},on:{"click":function($event){return _vm.setAndOppenInfo(question)}}},[_c('img',{attrs:{"src":require("@/assets/images/info.svg")}})])]),(_vm.hasUpdate)?_c('li',[_c('button',{attrs:{"title":_vm.$t('general.edit')},on:{"click":function($event){_vm.setQuestionData(question);
                  _vm.openBottomSheet(_vm.model.metaData.components.Update.refName);}}},[_c('img',{attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(_vm.hasFinalDelete)?_c('li',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("" + (_vm.model.metaData.components.Delete.refName))),expression:"`${model.metaData.components.Delete.refName}`"}],attrs:{"title":_vm.$t('general.delete')},on:{"click":function($event){return _vm.setQuestionData(question)}}},[_c('img',{attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e(),(_vm.hasChangeActivationType)?_c('li',[_c('button',{attrs:{"title":_vm.$t('changeActivationType')},on:{"click":function($event){return _vm.setAndOppenChangeActivationType(question)}}},[_c('img',{attrs:{"src":require("@/assets/images/changeActivationType.svg")}})])]):_vm._e()])],1)])}),0)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{attrs:{"rowspan":"2"}},[_c('i',{staticClass:"fas fa-sliders-h"})])}]

export { render, staticRenderFns }