var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"pagination-with-content"},[[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:("SelectedEducationalCourseLevel"),expression:"`SelectedEducationalCourseLevel`"}],staticClass:"btn btn-lg btn-collapse"},[_c('span',[_vm._v(_vm._s(_vm.$t("EducationalCourseLevels.name"))+" ")]),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"SelectedEducationalCourseLevel"}},[_c('div',{staticClass:"row data-collapse"},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevels.educationalCourseLevel
                      .educationalCourseLevelNameCurrent,"title":_vm.$t('general.name'),"imgName":'EducationalCourseLevels.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevels.educationalCourseLevel
                      .educationalCourseInfo
                      ? _vm.educationalCourseLevels.educationalCourseLevel
                          .educationalCourseInfo.educationalCourseNameCurrent
                      : '',"title":_vm.$t('EducationalCourses.name'),"imgName":'EducationalCourses.svg'}})],1)])])],1)])],_c('CustomPageHeader',{attrs:{"canAdd":_vm.canAdd,"canSort":false,"canChangeActivationType":false,"canToggleView":false,"model":_vm.educationalCourseLevelQuestions,"addFullbackAction":_vm.getAllEducationalCourseLevelQuestions,"filterFullbackAction":_vm.getAllEducationalCourseLevelQuestions,"sortFullbackAction":_vm.getAllEducationalCourseLevelQuestions,"toggelActivationFullbackAction":_vm.getAllEducationalCourseLevelQuestions}}),(_vm.hasData)?[_c('EducationalCourseLevelQuestionTable',{attrs:{"model":_vm.educationalCourseLevelQuestions,"updateFullbackAction":_vm.getAllEducationalCourseLevelQuestions},on:{"setEducationalCourseLevelQuestionData":function($event){return _vm.educationalCourseLevelQuestions.educationalCourseLevelQuestion.fillData(
            $event
          )},"setQuestionData":function($event){return _vm.questions.question.fillData($event)}}}),_c('EducationalCourseLevelQuestionInfo',{attrs:{"model":_vm.educationalCourseLevelQuestions}}),_c('QuestionMedia',{attrs:{"model":_vm.questions}}),_c('CustomPagination',{attrs:{"paginationData":_vm.educationalCourseLevelQuestions.filterData},on:{"changePagination":function($event){return _vm.changePagination($event)}}})]:_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg,"image":_vm.exceptionImg}}):_vm._e(),_c('EducationalCourseLevelQuestionFilter',{attrs:{"model":_vm.educationalCourseLevelQuestions,"educationalCourseLevelsModel":_vm.educationalCourseLevels,"fullbackAction":_vm.getAllEducationalCourseLevelQuestions}}),_c('EducationalCourseLevelQuestionAdd',{attrs:{"model":_vm.educationalCourseLevelQuestions,"educationalCourseLevelsModel":_vm.educationalCourseLevels,"fullbackAction":_vm.getAllEducationalCourseLevelQuestions}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }